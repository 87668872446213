import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { doorDash, skipTheDishes } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Delivery Provider'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a
            href={`https://www.skipthedishes.com/sushi-bar-Iori-lougheed-highway?utm_source=google&utm_medium=organic&utm_campaign=gpa`}
          >
            <CFView hover>
              <CFImage maxWidth="290px" src={skipTheDishes} alt="Door Dash" />
            </CFView>
          </a>
          <a
            href={`https://www.doordash.com/en-CA/store/sushi-bar-iori-maple-ridge-580635/?utm_campaign=gpa`}
          >
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={doorDash} alt="Skip the Dishes" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
