import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DeliveryModal from './DeliveryModal'
import {
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  orderDeliveryButton,
} from 'images'

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFView column justifyStart alignCenter mt="60px">
            <CFImage src={mobileHeroText} w="90%" alt="mobile hero text" />
            <CFView>
              <CFView pulsate mt="30px">
                <OrderPickupButton />
              </CFView>
              {/* <CFView hover pt="10px" onClick={() => setShowModal(true)}>
                <CFImage
                  maxWidth="260px"
                  src={orderDeliveryButton}
                  alt="Order delivery"
                />
              </CFView>*/}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="685px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignCenter
        >
          <CFView w="100%" column justifyStart alignStart pl="7.5%" pt="7%">
            <CFImage src={heroText} w="40%" minWidth="480px" alt="heroText" />
            <CFView mt="25px">
              <OrderPickupButton />
            </CFView>
            {/*<CFView hover pt="10px" onClick={() => setShowModal(true)}>
              <CFImage
                maxWidth="325px"
                src={orderDeliveryButton}
                alt="Order delivery"
              />
            </CFView>*/}
          </CFView>
        </CFView>
      </DefaultScreen>

      <DeliveryModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
